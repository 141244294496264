
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import globalFunctions from "@/components/globalFunctions.vue";

export default defineComponent({
  name: "user",
  components: {
    ErrorMessage,
    Field,
    Form,
    globalFunctions,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const busyStatus = ref("false");
    const date = ref("");
    const userID = ref();
    const regions = ref([]);
    let errorsRef = ref(null);
    const profileDetailsValidator = computed(() => {
      return validator();
    });

    function validator() {
      let validation;
      validation = {
        first_name: Yup.string().required().label("First name"),
        last_name: Yup.string().required().label("Last name"),
        company: Yup.string().required().label("Company"),
        email: Yup.string().required().email().label("Email"),
        phone: Yup.string().required().label("phone"),
        address: Yup.string().required().label("Address"),
        postal_code: Yup.string().required().label("Zip/Postal Code"),
        country: Yup.string().required().label("Country"),
        region: Yup.string().required().label("Region"),
        status: Yup.string().required().label("Status"),
        city: Yup.string().required().label("City"),
        province: Yup.string().required().label("Province"),
        hide: Yup.string().required().label("Show Welcome Message"),
      };
      if (busyStatus.value == "true") {
        validation = {
          first_name: Yup.string().required().label("First name"),
          last_name: Yup.string().required().label("Last name"),
          company: Yup.string().required().label("Company"),
          email: Yup.string().required().email().label("Email"),
          phone: Yup.string().required().label("phone"),
          address: Yup.string().required().label("Address"),
          postal_code: Yup.string().required().label("Email"),
          country: Yup.string().required().label("Country"),
          region: Yup.string().required().label("Region"),
          status: Yup.string().required().label("Status"),
          city: Yup.string().required().label("City"),
          province: Yup.string().required().label("Province"),
          busy: Yup.date().required().label("Date"),
          hide: Yup.date().required().label("Show Welcome Message"),
        };
      }
      return Yup.object().shape(validation);
    }

    const profileDetails = ref([]);
    const submit = () => {
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButton.value || count > 0) {
        return;
      }
      submitButton.value.setAttribute("disabled", "disabled");

      submitButton.value.setAttribute("data-kt-indicator", "on");
      let json = JSON.parse(JSON.stringify(profileDetails.value));
      busyStatus.value == "false" ? delete json.busy : null;
      apiService
        .put(`/user/${userID.value}`, json)
        .then(() => {
          success();
        })
        .catch(function (error) {
          submitButton.value?.removeAttribute("data-kt-indicator");
          submitButton.value?.removeAttribute("disabled");
          if (error.response && error.response.status == 422) {
            getError(error.response?.data.errors);
          }
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };

    function getError(error) {
      errorsRef.value = error;
      if (submitButton.value) {
        submitButton.value?.removeAttribute("data-kt-indicator");
        submitButton.value?.removeAttribute("disabled");
      }
    }

    function success() {
      if (submitButton.value) {
        submitButton.value?.removeAttribute("data-kt-indicator");
        submitButton.value?.removeAttribute("disabled");
      }
      Swal.fire({
        text: `The User has been successfully updated!`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    }

    const getUser = () => {
      userID.value = Number(route.params.id)
        ? route.params.id
        : router.push({ name: "users" });
      apiService
        .get(`/user/${userID.value}`)
        .then(({ data }) => {
          busyStatus.value = data?.data.busy ? "true" : "false";
          if (data?.data) {
            profileDetails.value = data?.data;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    const getRegion = (resp) => {
      regions.value = resp;
    };
    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };
    onMounted(async () => {
      await setCurrentPageBreadcrumbs("Edit", ["Users"]);
      await getUser();
    });
    return {
      submitButton,
      submit,
      getUser,
      profileDetails,
      profileDetailsValidator,
      busyStatus,
      date,
      errorsRef,
      hideError,
      getRegion,
      regions,
    };
  },
});
